import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <InternalContent mdxType="InternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-getting-started--docs"
          }}>{`Canvas Tokens`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/api-pattern-guidelines"
          }}>{`API & Pattern Guidelines`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/canvas-kit-labs"
          }}>{`Canvas Kit Labs`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/canvas-kit-preview"
          }}>{`Canvas Kit Preview`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/compound-components"
          }}>{`Compound Components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/creating-compound-components"
          }}>{`Creating Compound Components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/maintaining"
          }}>{`Maintaining Canvas Kit`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/style-props"
          }}>{`Style Props`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/testing"
          }}>{`Testing`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/working-with-figma"
          }}>{`Working with Figma`}</a></li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday.github.io/canvas-tokens/?path=/docs/docs-getting-started--docs"
          }}>{`Canvas Tokens`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/api-pattern-guidelines"
          }}>{`API & Pattern Guidelines`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/canvas-kit-labs"
          }}>{`Canvas Kit Labs`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/canvas-kit-preview"
          }}>{`Canvas Kit Preview`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/compound-components"
          }}>{`Compound Components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/creating-compound-components"
          }}>{`Creating Compound Components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/maintaining"
          }}>{`Maintaining Canvas Kit`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/style-props"
          }}>{`Style Props`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/get-started/for-developers/resources/testing"
          }}>{`Testing`}</a></li>
      </ul>
    </ExternalContent>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/get-started/for-developers/resources/collection-api"
        }}>{`Collection API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/get-started/for-developers/resources/combobox"
        }}>{`Combobox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/get-started/for-developers/resources/responsive-styling"
        }}>{`Responsive Styling`}</a></li>
    </ul>
    <h2 {...{
      "id": "links"
    }}>{`Links`}</h2>
    <InternalContent mdxType="InternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/Workday/canvas-tokens"
          }}>{`Canvas Tokens Github`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/Workday/canvas-kit"
          }}>{`Canvas Kit on GitHub`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday.github.io/canvas-kit"
          }}>{`Canvas Kit Storybook`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ghe.megaleo.com/design/anthology"
          }}>{`Anthology`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://developer.workday.com/"
          }}>{`Workday Extend`}</a></li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/Workday/canvas-tokens"
          }}>{`Canvas Tokens Github`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/Workday/canvas-kit"
          }}>{`Canvas Kit on GitHub`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday.github.io/canvas-kit"
          }}>{`Canvas Kit Storybook`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://developer.workday.com/"
          }}>{`Workday Extend`}</a></li>
      </ul>
    </ExternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      